import {supportsPassiveEvents} from 'detect-it';
export const colorVariants = ['gold', 'goldLight', 'goldDark', 'white', 'black', 'goldGradient', 'bratGreen'];

export const variantsWithTheme = {
  blackToDarkgreen: 'gold',
  greenToDarkgreen: 'white',
  whiteToIvory: 'goldDark',
  plumToPlum: 'goldLight',
  goldToDarkGold: 'white',
  grayToBlack: 'gold',
  darkgreen: 'gold',
  green: 'goldLight',
  ivory: 'goldDark',
  black: 'goldLight',
  forest: 'goldLight',
  olive: 'white',
  gold: 'black',
  poppy: 'white',
  plum: 'goldLight',
  midnight: 'goldLight',
};

export const themesToOptions = themes => {
  return Object.keys(themes)
    .filter(v => v !== 'default')
    .sort((a, b) => (a.includes('To') ? -1 : 1));
};

export const pickVariantWithTheme = theme => {
  return variantsWithTheme[theme];
};

export const capitalize = (str = '') => {
  if (typeof str !== 'string') return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const passiveArg = supportsPassiveEvents ? {passive: true} : false;
export const activeArg = supportsPassiveEvents ? {passive: false} : true;
