// extracted by mini-css-extract-plugin
export var black = "text-module--black--4CVaG";
export var body = "text-module--body--X2h+Y";
export var bodyL = "text-module--bodyL--mKFHM";
export var bodyLMedium = "text-module--bodyLMedium--dwdgx";
export var bodyM = "text-module--bodyM--DEHKO";
export var bodyMMedium = "text-module--bodyMMedium--p6yi8";
export var bodyMedium = "text-module--bodyMedium--bSNJ-";
export var bodyS = "text-module--bodyS--nOp8U";
export var bodySMedium = "text-module--bodySMedium--ZsJ4o";
export var bodyXl = "text-module--bodyXl---NYOR";
export var bodyXlMedium = "text-module--bodyXlMedium--NplOl";
export var bratGreen = "text-module--bratGreen--T7l6Y";
export var gold = "text-module--gold--aL71H";
export var goldDark = "text-module--goldDark--HAfDq";
export var goldGradient = "text-module--goldGradient--rBXv-";
export var goldLight = "text-module--goldLight--0pInM";
export var headingL = "text-module--headingL--87+o8";
export var headingM = "text-module--headingM--6z7bv";
export var headingS = "text-module--headingS--hYWCq";
export var headingXl = "text-module--headingXl--Y76lb";
export var headingXs = "text-module--headingXs---Fthn";
export var headingXxl = "text-module--headingXxl--+P3G6";
export var headingXxs = "text-module--headingXxs--TQGnv";
export var headingXxxl = "text-module--headingXxxl--WM-B0";
export var promoL = "text-module--promoL--bOYS8";
export var promoM = "text-module--promoM--EQJiQ";
export var promoS = "text-module--promoS--Fu3G4";
export var promoXl = "text-module--promoXl--e9X5S";
export var smallcaps = "text-module--smallcaps--wpkTr";
export var smallcapsMedium = "text-module--smallcapsMedium--jgX0l";
export var spanner = "text-module--spanner--3uHAJ";
export var subtext = "text-module--subtext--WhNxc";
export var subtextMedium = "text-module--subtextMedium--0hP+f";
export var white = "text-module--white--pBBKI";
export var withLineAbove = "text-module--withLineAbove---yYl2";