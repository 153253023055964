// extracted by mini-css-extract-plugin
export var black = "themes-module--black--xdW9P";
export var blackToDarkgreen = "themes-module--blackToDarkgreen--gY8sq";
export var darkblue = "themes-module--darkblue--1c60x";
export var darkgreen = "themes-module--darkgreen--5ZbG4";
export var forest = "themes-module--forest--To62f";
export var gold = "themes-module--gold--OTFsW";
export var goldToDarkGold = "themes-module--goldToDarkGold--t5935";
export var grayToBlack = "themes-module--grayToBlack--S4ddV";
export var green = "themes-module--green--YIpV+";
export var greenToDarkgreen = "themes-module--greenToDarkgreen--tJBkf";
export var ivory = "themes-module--ivory--HHrls";
export var midnight = "themes-module--midnight--m-Zw3";
export var olive = "themes-module--olive--vd0e0";
export var plum = "themes-module--plum--4DEIz";
export var plumToPlum = "themes-module--plumToPlum--OCy+j";
export var poppy = "themes-module--poppy--wwm9F";
export var whiteToIvory = "themes-module--whiteToIvory--V+C4R";