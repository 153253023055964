// extracted by mini-css-extract-plugin
export var content = "Hero-module--content--Jb6bw";
export var divider = "Hero-module--divider--XPGzA";
export var dividerCornflower = "Hero-module--dividerCornflower--70aon";
export var eyebrow = "Hero-module--eyebrow--KeNYu";
export var image = "Hero-module--image--c5AXf";
export var imageElement = "Hero-module--imageElement--pOoik";
export var inlay = "Hero-module--inlay--V4C4E";
export var reverse = "Hero-module--reverse--FE0Hx";
export var root = "Hero-module--root--lwean";
export var text = "Hero-module--text--J46Kx";
export var title = "Hero-module--title--qY4IL";
export var titleNarrowViewport = "Hero-module--titleNarrowViewport--ROySP";
export var titleWideViewport = "Hero-module--titleWideViewport--bH7HL";
export var withImage = "Hero-module--withImage--qfdsC";
export var withInlay = "Hero-module--withInlay--DZorv";
export var withRound = "Hero-module--withRound--PVbRz";